import { useLazyQuery, useQuery } from '@apollo/client'
import { DataCols, H4, PageMaxWidth, Panel, Button } from '@clubspark-react/clubspark-react-tools'
import { Grid, Hidden } from '@material-ui/core'
import { navigate } from 'gatsby'
import React, { useCallback, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useControlledQuery } from 'src/components/table-controls/table-controls'
import {
  GetMemberships,
  GetMemberships_memberships_data as Membership
} from 'src/graphql-types/memberships/GetMemberships'
import { firstCapital } from 'src/utils/helper/membership'
import AdminTable from '../admin-table/admin-table'
import EmptyState from '../empty-state/empty-state'
import ExpansionList from '../expansion-list/expansion-list'
import { LabelVariety } from '../status-label/status-label'
import * as styles from './membership-home.module.less'
import { GET_MEMBERSHIPS, GET_MEMBERSHIPS_BASIC_DATA } from './membership-home-queries'

const MembershipHome: React.FC = () => {
  const { t: tl } = useTranslation()

  const getStatusColor = (status: string): LabelVariety => {
    switch (status) {
      case 'ACTIVE':
        return 'success'
      case 'HIDDEN':
        return 'warning'
      case 'ARCHIVED':
        return 'error'
      case 'DIRECT_LINK':
        return 'neutral'
      default:
        return 'neutral2'
    }
  }

  const transformIfOrganisation = type => {
    if (type.match(/organisation/gi)) {
      return 'Organization'
    } else {
      return type
    }
  }

  const getTotalItems = useCallback((d: any) => d.memberships.totalEntries, [])

  const getSortProperty = useCallback(key => {
    return key
  }, [])

  const { data, loading, controlProps } = useControlledQuery<GetMemberships>(GET_MEMBERSHIPS, {
    getTotalItems,
    getSortProperty,
    transformVariables
  })

  const membershipIds = data?.memberships?.data?.map(m => m.id)

  // *MBD = MembershipsBasicData
  const [loadMBD, { called: calledMBD, data: dataMBD, loading: loadingMBD }] = useLazyQuery(
    GET_MEMBERSHIPS_BASIC_DATA,
    {
      variables: { membershipIds }
    }
  )

  useEffect(() => {
    if (membershipIds) {
      loadMBD()
    }
  }, membershipIds)

  const cols: DataCols<Membership> = useMemo(
    () => [
      {
        key: 'name',
        title: tl('name'),
        getValue: m => <p className={styles.nameLabel}>{m.name}</p>
      },
      { key: 'code', title: tl('code'), getValue: m => m.code },
      {
        key: 'type',
        title: tl('type'),
        getValue: m => firstCapital(m.type ? transformIfOrganisation(m.type) : '')
      },
      // {
      //   key: 'start',
      //   title: tl('start'),
      //   getValue: m => tl('memberships dates', { date: moment.utc(m.start) })
      // },
      // {
      //   key: 'end',
      //   title: tl('end'),
      //   getValue: m => tl('memberships dates', { date: moment.utc(m.end) })
      // },
      // { key: 'sold', title: tl('sold'), getValue: m => getMembershipBasicData(m.id)?.sold ?? '' },
      {
        key: 'members',
        title: tl('members'),
        getValue: m => getMembershipBasicData(m.id)?.membersCount ?? ''
      }
      // {
      //   key: 'state',
      //   title: tl('status'),
      //   getValue: m => (
      //     <StatusLabel variety={getStatusColor(m.state ?? '')}>
      //       {firstCapital(m.state ?? '')}
      //     </StatusLabel>
      //   )
      // }
    ],
    [tl, dataMBD]
  )

  const headers: DataCols<Membership> = useMemo(
    () => [{ key: 'title', title: tl('title'), getValue: m => m.name }],
    [tl]
  )

  const handleOnClickTableRow = (t: Membership) => {
    navigate(`/memberships/${t.id}`)
  }

  const handleExpansionListClick = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    t: Membership
  ) => {
    navigate(`/memberships/${t.id}`)
  }

  function transformVariables(optionsWithControls) {
    const { limit = 10, offset = 0, sorts = [] } = optionsWithControls ?? {}
    const [sortsObject = {}] = sorts
    const { sortDirection = '', property = '' } = sortsObject

    const transformSortDirection = (direction: string) => {
      if (direction === 'DESCENDING') {
        return 'DESC'
      } else if (direction === 'ASCENDING') {
        return 'ASC'
      } else {
        return 'NONE'
      }
    }

    const sorting = () => {
      if (sortDirection === '' || property === '') {
        // Return empty object to be destructured if there are no sorting properties
        return {}
      } else {
        return {
          sorting: {
            sortBy: property.toUpperCase(),
            sortDirection: transformSortDirection(sortDirection)
          }
        }
      }
    }

    return {
      ...sorting(),
      pagination: {
        page: offset / limit + 1,
        entriesPerPage: limit
      }
    }
  }

  function getMembershipBasicData(membershipId: string): Record<string, number> {
    const metadata = dataMBD?.membershipsBasicData?.find(m => m.membershipId === membershipId)

    return {
      sold: metadata?.successfulTransactionsCount,
      membersCount: metadata?.membersCount
    }
  }

  return (
    <PageMaxWidth>
        <Panel>
          {data?.memberships?.data?.length === 0 ? (
            <EmptyState title="There are no memberships" icon="lg-empty-state" />
          ) : (
            <>
              <Grid container alignItems="center">
                <H4 spacing={{ margins: { xs: 'bottom' } }}>{tl('all memberships')}</H4>
                {/*button will be added in phase 2 */}
                {/* <Button
                  size="sm"
                  spacing={{ margins: { auto: 'left' } }}
                  onClick={() => navigate('/memberships/create-membership')}
                  level="secondary"
                >
                  + Add Membership
                </Button> */}
              </Grid>
              <Hidden only="xs">
                <AdminTable
                  columns={cols}
                  data={data?.memberships?.data ? data?.memberships?.data : []}
                  onRowClick={handleOnClickTableRow}
                  loading={loading || (calledMBD && loadingMBD)}
                  // error={error}
                  controls={controlProps}
                />
              </Hidden>
              <Hidden smUp>
                <ExpansionList
                  columns={cols}
                  data={data?.memberships?.data!}
                  controls={controlProps}
                  loading={loading || (calledMBD && loadingMBD)}
                  handleClick={handleExpansionListClick}
                  headers={headers}
                  actions
                />
              </Hidden>
            </>
          )}
        </Panel>
      </PageMaxWidth>
  )
}

export default MembershipHome
