import graphql from 'graphql-tag'

export const GET_MEMBERSHIPS = graphql`
  query Memberships($filter: MembershipFilterOptionsInput!) {
    memberships(filter: $filter) {
      id
      name
      type
      code
      description
      benefits
      confirmation
      region
      tax
    }
  }
`
