import graphql from 'graphql-tag'

export const GET_MEMBERSHIPS = graphql`
  query GetMemberships($pagination: PaginationInput!, $sorting: SortingInput) {
    memberships(pagination: $pagination, sorting: $sorting) {
      data {
        id
        name
        code
        type
        terms {
          id
          name
          start
          end
          minAge
          maxAge
          state
        }
      }
      page
      entriesPerPage
      totalEntries
    }
  }
`

export const GET_MEMBERSHIPS_BASIC_DATA = graphql`
  query MembershipsBasicData($membershipIds: [String]) {
    membershipsBasicData(membershipIds: $membershipIds) {
      membershipId
      successfulTransactionsCount
      membersCount
    }
  }
`
