import React from 'react'
import Layout from 'src/components/layout/layout'
import MembershipHome from 'src/components/membership-home/membership-home'
import SEO from 'src/components/seo'
import { usePrivateRoute } from 'src/utils/auth'
import { getClientConfig } from 'src/config/config'
import { SaaSMemberships } from 'src/features/saas/saas-memberships/saas-memberships'

const MembershipsPage: React.FC = () => {
  usePrivateRoute()
  const { isSaaS } = getClientConfig()
  return (
    <Layout>
      <SEO title="Dashboard" />
      {isSaaS ? <SaaSMemberships /> : <MembershipHome />}
    </Layout>
  )
}

export default MembershipsPage
