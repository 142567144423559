import { Grid } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Memberships_memberships } from 'src/graphql-types/saas/Memberships'
import { capitalize } from 'src/utils/helper/rankings'
import { navigate } from 'gatsby'
import ROUTES from 'src/utils/routes'
import { retrieveToken } from 'src/utils/storage/local-storage'
import { useSaaSMemberships } from './saas-memberships.api'
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table'
import Panel from 'src/components/panel/panel'
import { PageMaxWidth } from 'src/components/util-components/util-components'
import EmptyState from 'src/components/empty-state/empty-state'
import { H4 } from 'src/components/typography/typography'
import { getOrgId } from 'src/apollo/local-state'
import { microservicesClient } from 'src/apollo/client'

export function SaaSMemberships() {
  const { t: tl } = useTranslation()

  const { memberships, loadingMemberships } = useSaaSMemberships({
    filter: {},
    options: {
      skip: !retrieveToken()
    }
  })

  const cols: DataCols<Memberships_memberships> = useMemo(
    () => [
      { key: 'name', title: tl('name'), getValue: m => m.name },
      { key: 'code', title: tl('code'), getValue: m => m.code },
      {
        key: 'type',
        title: tl('type'),
        getValue: m => capitalize(m?.type?.toLowerCase())
      }
    ],
    [tl]
  )

  return (
    <PageMaxWidth>
      <Panel>
        {!loadingMemberships && memberships?.length === 0 ? (
          <EmptyState title="There are no memberships" icon="lg-empty-state" />
        ) : (
          <>
            <Grid container alignItems="center">
              <H4 spacing={{ margins: { xs: 'bottom' } }}>{tl('memberships')}</H4>
            </Grid>
            <AdminTable
              columns={cols}
              data={memberships}
              loading={loadingMemberships}
              onRowClick={row => navigate(`${ROUTES.MEMBERSHIPS}/${row.id}`)}
            />
          </>
        )}
      </Panel>
    </PageMaxWidth>
  )
}
